<template>
  <client-form @update:client="redirectToClientsMainPage"></client-form>
</template>

<script>
import { CLIENTS_MAIN_PAGE_URL } from '../config';
import ClientForm from './ClientForm.vue';

export default {
  components: {
    ClientForm,
  },

  methods: {
    redirectToClientsMainPage() {
      window.location.href = CLIENTS_MAIN_PAGE_URL;
    },
  },
};
</script>
