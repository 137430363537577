import { FLASH_CLOSE } from '../config';

const flashElRemoveOnTimeout = document.querySelectorAll('.flash--fade');
const flashCloseEl = document.querySelectorAll('.flash__btn-close');

flashCloseEl.forEach((el) => {
  el.addEventListener('click', (event) => {
    const flashEl = event.target.closest('.flash');
    if (!flashEl) return;
    flashEl.remove();
  });
});

flashElRemoveOnTimeout.forEach((el) => {
  setTimeout(() => el.remove(), FLASH_CLOSE);
});
