import axios from 'axios';

export class OrderService {
  static async getOrderById(order_id) {
    const response = await axios.get(`/api/v2/orders/${order_id}`);
    return response.data;
  }

  static async listOrders(params) {
    const response = await axios.get('/api/v2/orders', {
      params: params,
      paramsSerializer: { indexes: null },
    });
    return response.data;
  }

  static async createNewOrder(jsonData, csrfToken) {
    const response = await axios.post('/api/v2/orders', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async editOrder(jsonData, csrfToken) {
    const response = await axios.put('/api/v2/orders', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async patchOrder(jsonData, csrfToken) {
    const response = await axios.patch(`/api/v2/orders`, jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async generateOrdersHistoryReportXlsx(jsonData, csrfToken) {
    const response = await axios.post('/api/v2/orders/reports/history/xlsx', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
      responseType: 'blob',
    });

    return {
      data: response.data,
      filename: response.headers['content-disposition'].match(/filename="(.+)"/)[1],
    };
  }
}
