<template>
  <order-config-pickup-location-form
    @update:pickup-location="redirectToListPickupLocationsPage"
  ></order-config-pickup-location-form>
</template>

<script>
import { ORDERS_LIST_PICKUP_LOCATIONS_URL } from '../config';
import OrderConfigPickupLocationForm from './OrderConfigPickupLocationForm.vue';

export default {
  components: {
    OrderConfigPickupLocationForm,
  },

  methods: {
    redirectToListPickupLocationsPage() {
      window.location.href = ORDERS_LIST_PICKUP_LOCATIONS_URL;
    },
  },
};
</script>
