import * as rules from 'vee-validate/dist/rules';
import { extend, localize } from 'vee-validate';
import Vue from 'vue';
import VueQuillEditor from 'vue-quill-editor';
import Vuetify from 'vuetify';
import en from 'vee-validate/dist/locale/en.json';

Vue.use(Vuetify);

// Configure VueQuillEditor
Vue.use(VueQuillEditor, {
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],

      [{ list: 'ordered' }, { list: 'bullet' }],

      [{ header: [1, 2, 3, false] }],

      ['clean'],
    ],
  },
});

// Configure Vee-Validate
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]); // eslint-disable-line
});
localize('en', en);

// --- Vue custom directives ---
// Adds a red asterisk to the label of the input element if the required attribute is present.
Vue.directive('required', {
  inserted: function (el, binding) {
    if (binding.value === false) {
      return;
    }

    const span = document.createElement('span');
    span.textContent = ' *';
    span.classList.add('red--text');

    const label = el.querySelector('label');
    if (label) {
      label.appendChild(span);
    } else {
      el.appendChild(span);
    }
  },
});

export default new Vuetify({});
