<template>
  <div>
    <validation-provider v-slot="{ errors }" name="Client" rules="required|selectedClientRequired">
      <v-combobox
        v-model="selectedClient"
        v-required
        label="Client"
        :loading="isSearchLoading"
        :search-input.sync="clientNamePattern"
        :error-messages="errors"
        :items="clients"
        :filter="stubFilter"
        item-value="id"
        item-text="name"
        hide-no-data
        @keyup.enter="searchClients"
        @keyup.enter.native.prevent
        @keydown.enter.native.prevent
      >
        <template #append-outer>
          <v-btn v-if="isClientEditable" small outlined color="primary" @click="openClientFormDialog">
            Edit Client
          </v-btn>

          <v-btn
            v-if="isNewClientCreation"
            color="primary"
            small
            :outlined="!clientNamePattern"
            @click="openClientFormDialog"
            >New Client</v-btn
          >
        </template>
      </v-combobox>
    </validation-provider>

    <client-form-dialog ref="clientFormDialog" @update:client="updateSelectedClient" />
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import { useDebounceFn } from '@vueuse/core';

import { ClientService } from '../services/ClientService';
import ClientFormDialog from './ClientFormDialog.vue';
import Flash from '../helpers/Flash';

const flash = new Flash('.flash--main');

export default {
  components: {
    ClientFormDialog,
    ValidationProvider,
  },

  props: {
    initialClient: {
      type: [Object, String],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isSearchLoading: false,

      clientNamePattern: '',

      clients: [],

      // Can be string or object
      // v-combobox allows custom input which is set to string
      selectedClient: {
        id: null,
        crmId: null,
        name: null,
        clientType: null,
        contacts: [],
        sendClientEmails: true,
        isDeleted: null,
      },
    };
  },

  computed: {
    isClientEditable() {
      return this.selectedClient?.id && this.selectedClient.isDeleted === false;
    },

    isNewClientCreation() {
      return !this.selectedClient?.id;
    },
  },

  watch: {
    initialClient(client) {
      this.selectedClient = {
        id: client.id,
        crmId: client.crmId,
        name: client.name,
        clientType: client.clientType,
        contacts: client.contacts,
        sendClientEmails: client.sendClientEmails,
        isDeleted: client.isDeleted,
      };
    },

    selectedClient(newClientItemOrName) {
      const data = {
        id: newClientItemOrName?.id || null,
        crmId: newClientItemOrName?.crmId || null,
        name: newClientItemOrName?.name || newClientItemOrName,
        contacts: newClientItemOrName?.contacts || [],
        clientType: newClientItemOrName?.clientType || null,
        sendClientEmails:
          newClientItemOrName?.sendClientEmails !== undefined ? newClientItemOrName.sendClientEmails : true,
        isDeleted: newClientItemOrName?.isDeleted || null,
      };

      this.$emit('update:selected-client', data);
    },

    clientNamePattern: useDebounceFn(
      function () {
        this.searchClients();
      },
      100,
      { maxWait: 300 },
    ),
  },

  created() {
    extend('selectedClientRequired', {
      validate: this.validateSelectedClientRequired,
      message: 'The {_field_} field is required',
    });
  },

  methods: {
    async searchClients() {
      if (!this.clientNamePattern || !this.clientNamePattern.trim()) {
        return;
      }

      flash.clear();
      this.isSearchLoading = true;

      try {
        const { clients } = await ClientService.listClients({ clientNamePattern: this.clientNamePattern });

        this.clients = clients;
      } catch (err) {
        console.error(err);

        flash.clear();
        flash.flash('Client search failed. Please reload the page and try again.', 'error', true);
      } finally {
        this.isSearchLoading = false;
      }
    },

    openClientFormDialog() {
      this.$refs.clientFormDialog.openDialog({
        clientId: this.selectedClient?.id,
        clientName: this.selectedClient?.id ? this.selectedClient.name : this.clientNamePattern,
      });
    },

    updateSelectedClient(updatedClient) {
      this.selectedClient = updatedClient;
    },

    stubFilter() {
      // Stub filter for searched text highlighting but no frontend client-side filtering
      return true;
    },

    validateSelectedClientRequired(selectedClient) {
      const hasClientId = Boolean(selectedClient?.id);
      const hasClientName = Boolean(selectedClient?.name);
      const isUnstructuredClientName = Boolean(typeof selectedClient === 'string' && selectedClient.trim());

      return hasClientId || hasClientName || isUnstructuredClientName;
    },
  },
};
</script>
