import Vue from 'vue';

import { page } from '../../helpers/page';
import ClientsListPage from '../../components/ClientsListPage.vue';
import Vuetify from '../../helpers/vuetify';

page('/clients', function () {
  const app = new Vue({
    vuetify: Vuetify,
    components: {
      ClientsListPage,
    },
  });

  app.$mount('#app');
});
