import Vue from 'vue';

import { page } from '../../helpers/page';
import ClientEditPage from '../../components/ClientEditPage.vue';
import Vuetify from '../../helpers/vuetify';

page(
  '/clients/edit',
  function () {
    const app = new Vue({
      vuetify: Vuetify,
      components: {
        ClientEditPage,
      },
    });

    app.$mount('#app');
  },
  true,
);
