import { format } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

const LOCAL_TIMEZONE = 'Europe/Riga';

export const utcDateTimeToLocalString = function (utcDateTime) {
  const localDateTime = toZonedTime(utcDateTime, LOCAL_TIMEZONE);
  return format(localDateTime, 'dd.MM.yyyy HH:mm');
};

export const utcDateTimeToLocalDateTimeInputString = function (utcDateTime) {
  const localDateTime = toZonedTime(utcDateTime, LOCAL_TIMEZONE);
  return format(localDateTime, "yyyy-MM-dd'T'HH:mm");
};

export const localDateTimeToUTCString = function (localDateTime) {
  const utcDateTime = fromZonedTime(localDateTime, LOCAL_TIMEZONE);
  return utcDateTime.toISOString();
};
