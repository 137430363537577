import Vue from 'vue';
import _ from 'lodash';
import draggable from 'vuedraggable';

import { CURRENCY, JSON_STRINGIFY_SPACE } from '../../config';
import { OrderConfigService } from '../../services/OrderConfigService';
import { SecurityService } from '../../services/SecurityService';
import { difference } from '../../helpers/functions';
import { page } from '../../helpers/page';
import Flash from '../../helpers/Flash';
import Vuetify from '../../helpers/vuetify';

page('/orders/config/select-fields', function () {
  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],
    components: {
      draggable,
    },
    data() {
      return {
        // Rendering page for the first time
        firstPageLoad: true,
        // AJAX request to GET order config in progress
        orderConfigLoading: false,
        // AJAX request failed
        orderConfigLoadingError: false,
        // AJAX request to SAVE order config in progress
        saveSelectFieldLoading: false,
        // The config
        ordersSelectFields: [],
        // VueDraggable is active
        isDragging: false,
        // Reference to SelectField that is currently being edited
        activeSelectField: {},
        // Deep copy of original SelectField before editing - for change comparison
        originalActiveSelectField: {},
        // Edit element dialog window state
        editElementDialog: false,
        // Reference to SelectFieldValue that is currently being edited
        editingElement: {},
        // Element edit dialog window model
        editElementModel: {
          value: '',
          meta: '',
        },
        // Original element object before edit
        editElementOriginalModel: {},
        // New element dialog window state
        newElementDialog: false,
        // New element dialog window model
        newElementModel: {},
        // Default new element values
        defaultElementModel: {
          value: '',
          meta: '',
          // New element is active by default
          is_active: true,
          // New element is not deleted by default
          is_deleted: false,
        },
      };
    },
    async created() {
      this.csrfToken = await SecurityService.getCsrfToken();

      await this.initOrdersSelectFields();

      this.firstPageLoad = false;
    },
    methods: {
      //////////////////////////////////////////////////////////
      //
      // Vue Draggable
      //
      //////////////////////////////////////////////////////////
      /**
       * Configure VueDraggable
       *
       * @param {Object} selectField
       * @returns {Object}
       */
      dragOptions(selectField) {
        return {
          group: selectField.id,
          animation: 200,
          disabled: this.activeSelectField.id != selectField.id,
          ghostClass: 'draggable__ghost',
        };
      },
      startDrag() {
        this.isDragging = true;
      },
      endDrag() {
        this.isDragging = false;
      },
      /**
       * Fired on VueDraggable move event
       */
      checkMove: function (event) {
        // Find to which box element is being moved, get its modelState (enabled/disabled)
        // and update is_active flag accordingly
        const toOrderConfigDraggable = event.to.closest('.order-config__draggable');
        if (!toOrderConfigDraggable) {
          return;
        }
        const originalState = event.draggedContext.element.is_active;
        if (toOrderConfigDraggable.dataset.modelState === 'enabled') {
          event.draggedContext.element.is_active = true;
        } else {
          event.draggedContext.element.is_active = false;
        }
        // Mark element as edited
        if (originalState != event.draggedContext.element.is_active) {
          event.draggedContext.element.edited = true;
        }
      },
      //////////////////////////////////////////////////////////
      //
      // SELECT FIELD (CARD) EDIT
      //
      //////////////////////////////////////////////////////////
      /**
       * Query SelectFields from backend
       */
      async initOrdersSelectFields() {
        try {
          this.orderConfigLoading = true;
          this.ordersSelectFields = this.transformOrdersSelectFields(
            await OrderConfigService.getOrderSelectFieldsConfig(true),
          );
        } catch (err) {
          console.error(err);
          this.orderConfigLoadingError = true;
          const flash = new Flash('.flash--order-config-head');
          // The only way out is to restart a page, hence no need to call flash.clear
          flash.flash('Order configuration loading has failed', 'error');
        } finally {
          this.orderConfigLoading = false;
        }
      },
      /**
       * Go into Select Field (card) edit mode
       *
       * @param {Object} selectField
       */
      async enableSelectFieldEdit(selectField) {
        this.activeSelectField = selectField;
        // Keep a copy for change diff on save
        this.originalActiveSelectField = _.cloneDeep(this.activeSelectField);
      },
      /**
       * Send request to backend to save Select Field
       */
      async saveSelectFieldChanges() {
        const flash = new Flash('.flash--order-config-head');
        flash.clear();
        try {
          this.saveSelectFieldLoading = true;
          await OrderConfigService.updateOrderSelectFieldConfig(this.transformSaveSelectFields(), this.csrfToken);
          flash.flash(`${this.activeSelectField.name} select field has been updated`, 'success', true);
          // Exit edit mode
          this.closeSelectFieldEdit();
        } catch (err) {
          console.error(err);
          flash.flash(`Order Config Saving Error: ${err.message}`, 'error');
        } finally {
          this.saveSelectFieldLoading = false;
        }
      },
      /**
       * Close Select Field edit
       */
      closeSelectFieldEdit() {
        this.activeSelectField = {};
        this.originalActiveSelectField = {};
        this.initOrdersSelectFields();
      },
      //////////////////////////////////////////////////////////
      //
      // EDIT ELEMENT
      //
      //////////////////////////////////////////////////////////
      /**
       * Toggle deleted
       *
       * @param {Object} element SelectFieldValue
       */
      toggleElementDeleted(element) {
        element.is_deleted ? (element.is_deleted = false) : (element.is_deleted = true);
        // Force update UI after deleted flag change
        this.$forceUpdate();
      },
      getSelectFieldValueStateArray(selectField, element) {
        return selectField.values[element.is_active ? 'enabled' : 'disabled'];
      },
      /**
       * Toggle element.is_active flag and move element to corresponding enabled/disabled state array
       *
       * @param {Object} element SelectFieldValue
       */
      toggleElementIsActive(element) {
        // Remove element from current SelectField state array
        const currentModel = this.getSelectFieldValueStateArray(this.activeSelectField, element);
        // Update is_active flag
        element.is_active ? (element.is_active = false) : (element.is_active = true);
        // Remove element from current model
        for (const index in currentModel) {
          // Find elements' index
          if (currentModel[index].id == element.id) {
            currentModel.splice(index, 1);
            break;
          }
        }
        // Add element to the end of opposite state model
        const oppositeModel = this.getSelectFieldValueStateArray(this.activeSelectField, element);
        oppositeModel.push(element);
      },
      /**
       * Open element edit dialog window with pre-filled values from given element
       *
       * @param {Object} element SelectFieldValue
       */
      openEditElementDialog(element) {
        // Fill form input fields with elements' data
        this.editElementModel.value = element.value;
        // Empty string if meta is not set
        this.editElementModel.meta = element.meta && JSON.stringify(element.meta, null, JSON_STRINGIFY_SPACE);
        // Save reference to original element
        this.editingElement = element;
        // Copy of original element
        this.editElementOriginalModel = { ...element };
        // Open dialog
        this.editElementDialog = true;
      },
      /**
       * Close element edit dialog window and reset form input values
       */
      closeEditElementDialog() {
        // Close dialog
        this.editElementDialog = false;
        // Clear reference to original element
        this.editingElement = {};
        this.editElementOriginalModel = {};
        // Clear form input fields
        this.editElementModel.value = '';
        this.editElementModel.meta = '';
      },
      /**
       * Save edited element changes
       */
      saveEditElementDialog() {
        const flash = new Flash('.flash--edit-element-dialog');
        flash.clear();
        try {
          // If validation passed, edit the original element
          const validatedEditElementModel = this.objectToSelectFieldValue(
            this.editElementModel,
            this.editElementOriginalModel,
          );
          // Mark element as edited
          if (
            this.editingElement.value != validatedEditElementModel.value ||
            !_.isEqual(this.editingElement.meta, validatedEditElementModel.meta)
          ) {
            this.editingElement.edited = true;
          }
          // Map edited values
          this.editingElement.value = validatedEditElementModel.value;
          this.editingElement.meta = validatedEditElementModel.meta;
          // Close dialog after save
          this.closeEditElementDialog();
        } catch (err) {
          // If validation failed, display error message and leave the dialog open
          flash.flash(err, 'error');
        }
      },
      //////////////////////////////////////////////////////////
      //
      // NEW ELEMENT
      //
      //////////////////////////////////////////////////////////
      openNewElementDialog() {
        // Clear form input fields
        this.newElementModel = { ...this.defaultElementModel };
        // Close dialog
        this.newElementDialog = true;
      },
      closeNewElementDialog() {
        // Clear form input fields
        this.newElementModel = { ...this.defaultElementModel };
        // Close dialog
        this.newElementDialog = false;
      },
      saveNewElementDialog() {
        const flash = new Flash('.flash--new-element-dialog');
        flash.clear();
        try {
          // If validation passed (not thrown), add new element at the end of enabled array
          const element = this.objectToSelectFieldValue(this.newElementModel);
          // Mark element as new
          element.new = true;
          // Save
          this.activeSelectField.values.enabled.push(element);
          // Close dialog after save
          this.closeNewElementDialog();
        } catch (err) {
          // If validation failed, display error message and leave the dialog open
          flash.flash(err, 'error');
        }
      },
      //////////////////////////////////////////////////////////
      //
      // Data transformation
      //
      //////////////////////////////////////////////////////////
      /**
       * Transform SelectField config coming from backend for
       * easier use on frontend.
       * Split SelectFields' values into enabled/disabled arrays
       *
       * @param {Array} ordersSelectFields
       * @returns {Array}
       */
      transformOrdersSelectFields(ordersSelectFields) {
        // For each order type
        return Object.values(ordersSelectFields.order_types).map((orderType) => {
          // For every selectField inside orderType
          orderType.select_fields = Object.values(orderType.select_fields).map((selectField) => {
            selectField.selectFieldEditCardLoading = false;
            return this.transformOrdersSelectField(selectField);
          });
          return orderType;
        });
      },
      transformOrdersSelectField(selectField) {
        // split by is_active flag into two arrays - enabled/disabled
        const enabled = selectField.values.filter((value) => value.is_active);
        const disabled = selectField.values.filter((value) => !value.is_active);
        // transform value param from array to object that contains enabled/disabled keys
        selectField.values = { enabled, disabled };
        // return modified selectField
        return selectField;
      },
      /**
       * Transform SelectField object for backend API structure
       *
       * @param {Object} selectField
       * @returns {Object}
       */
      transformSaveSelectFields() {
        const result = {
          id: this.activeSelectField.id,
          values: [...this.activeSelectField.values.enabled, ...this.activeSelectField.values.disabled],
        };
        result.values = result.values.map((el) => this.transformSaveSelectFieldValue(el));
        return result;
      },
      transformSaveSelectFieldValue(selectFieldValue) {
        const original = this.findOriginalSelectFieldValue(selectFieldValue);
        // If editing existing field, append only changed properties
        if (!_.isEmpty(original)) {
          const diff = { id: selectFieldValue.id, ...difference(selectFieldValue, original) };
          // If meta has changed, map it fully
          if ('meta' in diff) {
            diff.meta = selectFieldValue.meta;
          }
          return diff;
        }
        // Otherwise it is a new field
        return {
          id: selectFieldValue.id,
          is_active: selectFieldValue.is_active,
          is_deleted: selectFieldValue.is_deleted,
          value: selectFieldValue.value,
          meta: selectFieldValue.meta,
        };
      },
      findOriginalSelectFieldValue(selectFieldValue) {
        if (!selectFieldValue.id) {
          return;
        }
        return [
          ...this.originalActiveSelectField.values.enabled,
          ...this.originalActiveSelectField.values.disabled,
        ].find((el) => el.id == selectFieldValue.id);
      },
      /**
       * Pretty print metadata JSON as a String
       *
       * @param {Object} SelectFieldValue
       * @returns {String}
       */
      metadataToString(element) {
        const m = element.meta;
        // If no metadata on the field
        if (!m) {
          return '';
        }
        // Price field
        if (m.materialPrice) {
          // Unit is optional
          return `Material Price: ${m.materialPrice} ${CURRENCY}${m.materialUnit ? '/' + m.materialUnit : ''}`;
        }
        return '';
      },
      /**
       * Converts objects to SelectFieldValue and validates fields
       *
       * @param {Object} el SelectFieldValue after edit
       * @param {Object} originalEl SelectFieldValue before edit
       * @returns Validated and cleaned SelectFieldValue object
       * @throws Validation errors
       */
      objectToSelectFieldValue(el, originalEl = {}) {
        // 1. Value - mandatory, up to 512 characters, unique among SelectField
        const value = new String(el.value).trim();
        if (!value) {
          throw 'Value: This field is required.';
        }
        if (value.length > 512) {
          throw 'Value: Field cannot be longer than 512 characters.';
        }
        // If original value has not changed, do not validate uniqueness
        if (originalEl.value != value) {
          // Value is unique among all other elements in the same SelectField
          if (!this.selectFieldValueIsUnique(this.activeSelectField, value)) {
            throw `Value: Another Select Field with the same value (${value}) already exists.`;
          }
        }
        // 2. Meta - convert to JSON; optional
        let meta = {};
        try {
          // Default to null if meta is empty string
          meta = (el.meta && JSON.parse(el.meta)) || null;
        } catch (err) {
          throw 'Metadata: Invalid JSON syntax.';
        }
        // Return copy of original element with cleaned up data
        const resultEl = { ...el };
        resultEl.value = value;
        resultEl.meta = meta;
        return resultEl;
      },
      /**
       * Return true if SelectFieldValue.value is unique amongst given SelectField
       *
       * @param {Object} selectField SelectField model
       * @param {String} value SelectFieldValue.value
       * @returns {Boolean}
       */
      selectFieldValueIsUnique(selectField, value) {
        const foundElement = [...selectField.values.enabled, ...selectField.values.disabled].find(
          (element) => element.value === value,
        );
        return foundElement ? false : true;
      },
    },
  });
  app.$mount('#app');
});
