<template>
  <v-container>
    <v-row>
      <div class="flash flash--pickup-location-form"></div>
    </v-row>

    <validation-observer ref="orderPickupLocationFormValidationObserver">
      <v-form @submit.prevent="submitOrderPickupLocationForm">
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" name="Pickup Location Name" rules="required">
              <v-text-field
                v-model="pickupLocation.name"
                v-required
                label="Pickup Location Name"
                :error-messages="errors"
                :disabled="isPageLoading"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          class="mt-4"
          outlined
          text
          type="submit"
          :disabled="isPageLoading"
          :loading="isPageLoading"
        >
          {{ isNewPickupLocationCreation ? 'Create Pickup Location' : 'Edit Pickup Location' }}
        </v-btn>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { OrderConfigService } from '../services/OrderConfigService';
import { SecurityService } from '../services/SecurityService';
import Flash from '../helpers/Flash';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    pickupLocationId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isPageLoading: false,

      csrfToken: null,

      pickupLocation: {
        id: this.pickupLocationId,
        name: null,
      },
    };
  },

  computed: {
    isNewPickupLocationCreation() {
      return !this.pickupLocationId;
    },
  },

  watch: {
    async pickupLocationId(newPickupLocationId) {
      this.clearFormInputs();

      this.pickupLocation.id = newPickupLocationId;

      await this.initFormForPickupLocationCreationOrEdit();
    },
  },

  async mounted() {
    await this.initFormForPickupLocationCreationOrEdit();
  },

  methods: {
    async getCsrfToken() {
      this.csrfToken = await SecurityService.getCsrfToken();
    },

    async initFormForPickupLocationCreationOrEdit() {
      const flash = new Flash('.flash--pickup-location-form');
      flash.clear();

      this.isPageLoading = true;
      try {
        await this.getCsrfToken();

        if (!this.isNewPickupLocationCreation) {
          this.pickupLocation = await OrderConfigService.getPickupLocation(this.pickupLocationId);
        }

        this.isPageLoading = false;
      } catch (err) {
        console.error(err);

        flash.flash('Could not load the page. Please reload the page and try again.', 'error', false);
      }
    },

    async submitOrderPickupLocationForm() {
      const flash = new Flash('.flash--pickup-location-form');
      flash.clear();

      const isFormValid = await this.validatePickupLocationForm();
      if (!isFormValid) {
        return;
      }

      this.isPageLoading = true;

      const request = {
        id: this.pickupLocation.id || null,
        name: this.pickupLocation.name,
        csrfToken: this.csrfToken,
      };

      try {
        let pickupLocation;

        if (this.isNewPickupLocationCreation) {
          pickupLocation = await OrderConfigService.createPickupLocation(request);
        } else {
          pickupLocation = await OrderConfigService.editPickupLocation(request);
        }

        this.$emit('update:pickup-location', pickupLocation);
      } catch (err) {
        console.error(err);

        flash.flashFromBackendErrorResponse(
          err.response?.data,
          'Could not save the pickup location. Please reload the page and try again.',
        );
      } finally {
        this.isPageLoading = false;
      }
    },

    async validatePickupLocationForm() {
      this.$refs.orderPickupLocationFormValidationObserver.reset();
      return await this.$refs.orderPickupLocationFormValidationObserver.validate();
    },

    clearFormInputs() {
      this.pickupLocation = {
        id: null,
        name: null,
      };
    },
  },
};
</script>
