<template>
  <v-btn
    v-show="isScrollToTopVisible"
    v-scroll="onScroll"
    fab
    dark
    fixed
    bottom
    right
    color="primary"
    @click="scrollToTop"
  >
    <v-icon>keyboard_arrow_up</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      isScrollToTopVisible: false,
    };
  },

  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;

      const top = window.scrollY || e.target.scrollTop || 0;

      this.isScrollToTopVisible = top > 20;
    },

    scrollToTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
