<template>
  <div>
    <v-toolbar class="grey lighten-3">
      <v-toolbar-title>Order Pickup Locations</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" href="/orders/config/pickup/new">New Pickup Location</v-btn>
    </v-toolbar>

    <v-text-field
      v-model="search"
      class="p-3"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      clearable
    >
    </v-text-field>

    <v-data-table
      :headers="headers"
      :items="pickupLocations"
      :loading="loading"
      :search="search"
      item-key="id"
      :footer-props="footerProps"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
    >
      <template v-if="error" #body>
        <tbody>
          <tr class="text-center">
            <td colspan="100%">Table loading has failed. Please refresh the page and try again.</td>
          </tr>
        </tbody>
      </template>

      <template #[`item.createdAt`]="{ item }">
        <td>{{ utcDateTimeToLocalString(item.createdAt) }}</td>
      </template>

      <template #[`item.id`]="{ item }">
        <td>
          <v-btn icon :href="`/orders/config/pickup/qr-code/${item.id}`" target="_blank">
            <v-icon>qr_code</v-icon>
          </v-btn>

          <v-btn icon :href="`/orders/config/pickup/edit/${item.id}`">
            <v-icon>edit</v-icon>
          </v-btn>

          <v-btn icon @click="deletePickupLocation(item)">
            <v-icon>delete</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <div class="text-center pt-2 pb-5">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <scroll-to-top-fab></scroll-to-top-fab>
  </div>
</template>

<script>
import { ITEMS_PER_PAGE_DEFAULT, ITEMS_PER_PAGE_OPTIONS } from '../config';
import { OrderConfigService } from '../services/OrderConfigService';
import { SecurityService } from '../services/SecurityService';
import { dataTableLoadData } from '../helpers/functions';
import { utcDateTimeToLocalString } from '../helpers/dates';
import Flash from '../helpers/Flash';
import ScrollToTopFab from './ScrollToTopFab.vue';

export default {
  components: {
    ScrollToTopFab,
  },

  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
      footerProps: {
        itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      },
      search: null,
      loading: false,
      error: false,

      csrfToken: null,

      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Created At',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: 'Actions',
          value: 'id',
        },
      ],

      pickupLocations: [],
    };
  },

  async mounted() {
    await Promise.all([this.getCsrfToken(), this.queryPickupLocationsList()]);
  },

  methods: {
    utcDateTimeToLocalString,

    async getCsrfToken() {
      this.csrfToken = await SecurityService.getCsrfToken();
    },

    async queryPickupLocationsList() {
      const boundDataTableLoadData = dataTableLoadData.bind(this);

      await boundDataTableLoadData('pickupLocations', async () => {
        const response = await OrderConfigService.listPickupLocations();
        return response.pickupLocations;
      });
    },

    async deletePickupLocation(pickupLocation) {
      const flash = new Flash('.flash--main');
      flash.clear();

      if (!confirm(`Delete the '${pickupLocation.name}' pickup location? This can't be undone.`)) {
        return;
      }

      try {
        await OrderConfigService.deletePickupLocation({ id: pickupLocation.id, csrfToken: this.csrfToken });

        await this.queryPickupLocationsList();

        flash.flash('Pickup location has been deleted.', 'success', true);
      } catch (err) {
        flash.flash('Could not delete the pickup location. Please reload the page and try again.', 'error', true);

        console.error(err);
      }
    },
  },
};
</script>
