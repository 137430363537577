import axios from 'axios';

export class OrderExternalService {
  static async getOrderConfig(orderId, secretKey) {
    const response = await axios.get(`/api/v2/orders/config/external/${orderId}`, {
      params: { secret_key: secretKey },
    });

    return OrderExternalService.deserializeOrderConfig(response.data);
  }

  static async getOrderById(orderId, secretKey) {
    const response = await axios.get(`/api/v2/orders/external/${orderId}`, {
      params: { secret_key: secretKey },
    });
    return response.data;
  }

  static async editOrder(jsonData, csrfToken) {
    const response = await axios.put('/api/v2/orders/external', jsonData, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static deserializeOrderConfig(orderConfig) {
    return {
      statuses: orderConfig.statuses,
      pickupLocations: orderConfig.pickup_locations.map((pickupLocation) => {
        return OrderExternalService.deserializePickupLocationObject(pickupLocation);
      }),
    };
  }

  static deserializePickupLocationObject(pickupLocation) {
    return {
      id: pickupLocation.id,
      name: pickupLocation.name,
      qrCodePayload: pickupLocation.qr_code_payload,
      createdAt: pickupLocation.created_at,
      updatedAt: pickupLocation.updated,
    };
  }
}
