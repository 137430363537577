import Vue from 'vue';

import { page } from '../../helpers/page';

page(
  '/users/edit',
  function () {
    const app = new Vue({
      delimiters: ['[[', ']]'],

      data() {
        return {};
      },

      methods: {
        async deleteUser(event) {
          if (!confirm("Delete user? This can't be undone.")) {
            event.preventDefault();
            return;
          }
        },
      },
    });

    app.$mount('#app');
  },

  true,
);
