import { ORDER_STATUS_ID_DONE, ORDER_STATUS_ID_ERROR } from '../../config';
import { OrderExternalService } from '../../services/OrderExternalService';
import { SecurityService } from '../../services/SecurityService';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { getOrderEditExternalDetails } from '../../helpers/routeParams';
import { page } from '../../helpers/page';
import { required } from 'vee-validate/dist/rules';
import { scrollToFirstFormValidationError } from '../../helpers/veevalidate';
import Flash from '../../helpers/Flash';
import OrderPickupLocationSelector from '../../components/OrderPickupLocationSelector.vue';
import Vue from 'vue';
import Vuetify from '../../helpers/vuetify';

page(
  '/orders/external/edit',
  function () {
    const flash = new Flash('.flash--main');

    const app = new Vue({
      vuetify: Vuetify,
      delimiters: ['[[', ']]'],
      components: {
        ValidationProvider,
        ValidationObserver,
        OrderPickupLocationSelector,
      },

      data() {
        return {
          orderConfig: {
            pickupLocations: [],
          },

          csrfToken: null,

          isPageLoading: false,
          isOrderSubmitting: false,

          selectedOrderType: {
            id: null,
            code: null,
            name: null,
          },

          order: {
            id: null,
            secretKey: null,
            status: null,
            pickupLocation: null,
            errorDescription: null,
          },
        };
      },

      computed: {
        isOrderPickupLocationRequired() {
          return this.order.status == ORDER_STATUS_ID_DONE;
        },

        isErrorDescriptionRequired() {
          return this.order.status == ORDER_STATUS_ID_ERROR;
        },

        isOrderPickupLocationSelectorShown() {
          return this.isOrderPickupLocationRequired;
        },

        isErrorDescriptionFieldShown() {
          return this.isErrorDescriptionRequired;
        },
      },

      created() {
        // Custom validation rules
        extend('errorDescriptionRequired', {
          ...required,
          validate: this.validateErrorDescription,
          message: 'The {_field_} field is required for Error orders',
        });
      },

      async mounted() {
        this.isPageLoading = true;

        try {
          await Promise.all([this.getCsrfToken(), this.getOrderConfig(), this.getOrderForEdit()]);

          this.isPageLoading = false;
        } catch (err) {
          console.error(err);

          flash.flash('Could not load the page. Please reload the page and try again.', 'error', false);
        }
      },

      methods: {
        async getCsrfToken() {
          this.csrfToken = await SecurityService.getCsrfToken();
        },

        async getOrderConfig() {
          const { orderId, secretKey } = getOrderEditExternalDetails(window.location.href);

          this.orderConfig = await OrderExternalService.getOrderConfig(orderId, secretKey);
        },

        async getOrderForEdit() {
          const { orderId, secretKey } = getOrderEditExternalDetails(window.location.href);

          const order = await OrderExternalService.getOrderById(orderId, secretKey);

          // Current order type
          this.selectedOrderType.id = order.order_type.id;
          this.selectedOrderType.code = order.order_type.code;
          this.selectedOrderType.name = order.order_type.name;

          // Order details
          this.order.id = order.id;
          this.order.secretKey = secretKey;
          this.order.status = order.status.id;
          this.order.pickupLocation = order.pickup_location?.id || null;
          this.order.errorDescription = order.error_description;
        },

        async submitOrder() {
          flash.clear();

          const isFormValid = await this.$refs.formValidationObserver.validate();
          if (!isFormValid) {
            scrollToFirstFormValidationError(this.$el);
            return;
          }

          this.isOrderSubmitting = true;

          try {
            await OrderExternalService.editOrder(
              {
                id: this.order.id,
                order_type: this.selectedOrderType.id,
                secret_key: this.order.secretKey,
                status: this.order.status,
                pickup_location: this.order.pickupLocation || null,
                error_description: this.order.errorDescription,
              },
              this.csrfToken,
            );

            flash.flash('Order has been saved.', 'success', true);
          } catch (err) {
            console.error(err);

            flash.flashFromBackendErrorResponse(
              err.response?.data,
              'Could not edit the order. Please reload the page and try again.',
            );
          } finally {
            this.isOrderSubmitting = false;
          }
        },

        validateErrorDescription(_) {
          const errorDescriptionPlainText = this.$refs.errorDescriptionEditor.quill.getText().trim();

          return this.isErrorDescriptionRequired ? required.validate(errorDescriptionPlainText) : true;
        },
      },
    });

    app.$mount('#app');
  },
  true,
);
