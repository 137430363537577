import axios from 'axios';

export class OrderConfigService {
  static async getOrderConfig() {
    const response = await axios.get('/api/v2/orders/config');

    return OrderConfigService.deserializeOrderConfig(response.data);
  }

  static async getOrderConfigByOrder(orderId) {
    const response = await axios.get(`/api/v2/orders/config/${orderId}`);

    return OrderConfigService.deserializeOrderConfig(response.data);
  }

  static async getOrderTypesConfig() {
    const response = await axios.get('/api/v2/orders/config/types');
    return response.data;
  }

  static async getOrderSelectFieldsConfig(withInactiveSelectFieldValues = false) {
    const response = await axios.get('/api/v2/orders/config/types/select-fields', {
      params: {
        ...(withInactiveSelectFieldValues ? { with_inactive_select_field_values: '1' } : {}),
      },
      paramsSerializer: { indexes: null },
    });
    return response.data;
  }

  static async getOrderSelectFieldsConfigForExistingOrder(orderId) {
    const response = await axios.get(`/api/v2/orders/config/types/select-fields/orders/${orderId}`);
    return response.data;
  }

  static async updateOrderSelectFieldConfig(selectField, csrfToken) {
    const response = await axios.put('/api/v2/orders/config/select-fields', selectField, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });
    return response.data;
  }

  static async getPickupLocation(pickupLocationId) {
    const response = await axios.get(`/api/v2/orders/config/pickup/${pickupLocationId}`);

    return OrderConfigService.deserializePickupLocationObject(response.data);
  }

  static async listPickupLocations() {
    const response = await axios.get('/api/v2/orders/config/pickup');

    return {
      pickupLocations: response.data.pickup_locations.map((pickupLocation) => {
        return OrderConfigService.deserializePickupLocationObject(pickupLocation);
      }),
    };
  }

  static async createPickupLocation({ name, csrfToken }) {
    const response = await axios.post(
      '/api/v2/orders/config/pickup',
      { name },
      {
        headers: {
          'X-CSRFToken': csrfToken,
        },
      },
    );

    return OrderConfigService.deserializePickupLocationObject(response.data);
  }

  static async editPickupLocation({ id, name, csrfToken }) {
    const response = await axios.put(
      '/api/v2/orders/config/pickup',
      { id, name },
      {
        headers: {
          'X-CSRFToken': csrfToken,
        },
      },
    );

    return OrderConfigService.deserializePickupLocationObject(response.data);
  }

  static async deletePickupLocation({ id, csrfToken }) {
    const response = await axios.delete(`/api/v2/orders/config/pickup/${id}`, {
      headers: {
        'X-CSRFToken': csrfToken,
      },
    });

    return OrderConfigService.deserializePickupLocationObject(response.data);
  }

  static deserializeOrderConfig(orderConfig) {
    return {
      types: orderConfig.types,
      statuses: orderConfig.statuses,
      statusGroups: orderConfig.status_groups,
      priorities: orderConfig.priorities,
      filters: orderConfig.filters,
      managers: orderConfig.managers,
      pickupLocations: orderConfig.pickup_locations.map((pickupLocation) => {
        return OrderConfigService.deserializePickupLocationObject(pickupLocation);
      }),
    };
  }

  static deserializePickupLocationObject(pickupLocation) {
    return {
      id: pickupLocation.id,
      name: pickupLocation.name,
      qrCodePayload: pickupLocation.qr_code_payload,
      createdAt: pickupLocation.created_at,
      updatedAt: pickupLocation.updated,
    };
  }
}
