<template>
  <v-dialog v-model="open" max-width="800px">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h6">{{ title }}</div>

        <v-btn text icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <client-form
          ref="clientForm"
          :validation-disabled="!open"
          :client-id="client.id"
          :client-name="client.name"
          @update:client="updateSelectedClient"
        ></client-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientForm from './ClientForm.vue';

export default {
  components: {
    ClientForm,
  },

  data() {
    return {
      open: false,

      client: {
        id: null,
        name: null,
      },
    };
  },

  computed: {
    title() {
      return this.client.id ? `Edit Client #${this.client.id}` : 'Create Client';
    },
  },

  methods: {
    openDialog({ clientId, clientName }) {
      this.client = { id: clientId, name: clientName };
      this.open = true;
    },

    closeDialog() {
      this.open = false;
    },

    updateSelectedClient(selectedClient) {
      this.$emit('update:client', selectedClient);
      this.closeDialog();
    },
  },
};
</script>
