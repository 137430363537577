import { FLASH_CLOSE } from '../config';
import { stringToDOMElement } from './functions';

export default class Flash {
  #flashEl;

  constructor(flashContainerSelector = '.flash') {
    this.#flashEl = document.querySelector(flashContainerSelector);
    if (!this.#flashEl) throw new Error('Flash box element not found on the page');
  }

  /**
   * Display new Flash message
   *
   * @param {string} message
   * @param {string} category error/warning/success/info
   * @param {boolean} fade remove element from the DOM after FLASH_CLOSE timeout
   */
  flash(message, category = 'info', fade = false) {
    // Create flash message DOM element
    const flashMessageEl = stringToDOMElement(this._flashHTML(message, category));

    // Insert new flash message element to the DOM
    this.#flashEl.insertAdjacentElement('beforeend', flashMessageEl);

    // Flash close button
    const flashBtnCloseEl = flashMessageEl.querySelector('.flash__btn-close');
    if (flashBtnCloseEl) {
      flashBtnCloseEl.addEventListener('click', function () {
        flashMessageEl.remove();
      });
    }

    // Enable fade timeout
    if (fade) {
      setTimeout(() => flashMessageEl.remove(), FLASH_CLOSE);
    }
  }

  flashFromBackendErrorResponse(responseData, fallbackFlashMessage) {
    if (responseData?.validation_errors) {
      this._flashValidationErrors(responseData.validation_errors);
      return;
    }

    if (responseData?.error_code && responseData?.error_message) {
      this._flashDomainError(responseData);
      return;
    }

    this.flash(fallbackFlashMessage, 'error', false);
  }

  _flashDomainError(data) {
    this.flash(data.error_message, 'error', false);
  }

  _flashValidationErrors(validationErrors) {
    // Pydantic Validation Errors format

    for (const validationError of validationErrors) {
      // Join the location parts with spaces and replace underscores with spaces
      let fieldName = validationError.loc.length ? validationError.loc : [validationError.type];
      let fieldErrors = fieldName.map((part) => part.replace(/_/g, ' ')).join(' ');

      // Capitalize each word in a sentence
      fieldErrors = fieldErrors
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

      const errorMessage = `${fieldErrors}: ${validationError['msg']}`;
      this.flash(errorMessage, 'error', false);
    }
  }

  /**
   * Clear all flash messages
   */
  clear() {
    this.#flashEl.innerHTML = '';
  }

  _flashHTML(message, category) {
    return `<div class="flash__message flash__message--${category}">
        <span class="material-icons flash__icon flash__icon--${category}"></span>
        <div>${message}</div>
        <button type="button" class="btn-close flash__btn-close"></button>
    </div>`;
  }
}
