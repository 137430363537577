window.addEventListener('load', function () {
  const inputRequired = document.querySelectorAll('input[required]');
  const selectRequired = document.querySelectorAll('select[required]');
  const textareaRequired = document.querySelectorAll('textarea[required]');

  const inputEl = [...inputRequired, ...selectRequired, ...textareaRequired];

  inputEl.forEach((el) => {
    const labelEl = el.parentNode.getElementsByTagName('label')[0];
    if (!labelEl) return;
    labelEl.classList.add('form-label-required');
  });
});
