import OrderConfigEditPickupLocationPage from '../../components/OrderConfigEditPickupLocationPage.vue';
import Vue from 'vue';
import _ from 'lodash';

import { page } from '../../helpers/page';
import Vuetify from '../../helpers/vuetify';

page(
  '/orders/config/pickup/edit',
  function () {
    const app = new Vue({
      vuetify: Vuetify,
      components: {
        OrderConfigEditPickupLocationPage,
      },
    });

    app.$mount('#app');
  },
  true,
);
