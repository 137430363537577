import OrderConfigCreatePickupLocationPage from '../../components/OrderConfigCreatePickupLocationPage.vue';
import Vue from 'vue';
import _ from 'lodash';

import { page } from '../../helpers/page';
import Vuetify from '../../helpers/vuetify';

page('/orders/config/pickup/new', function () {
  const app = new Vue({
    vuetify: Vuetify,
    components: {
      OrderConfigCreatePickupLocationPage,
    },
  });

  app.$mount('#app');
});
