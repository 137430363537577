import { OrderConfigService } from '../../services/OrderConfigService';
import { OrderService } from '../../services/OrderService';
import { SecurityService } from '../../services/SecurityService';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { page } from '../../helpers/page';
import { saveAs } from 'file-saver';
import { scrollToFirstFormValidationError } from '../../helpers/veevalidate';
import Flash from '../../helpers/Flash';
import Vue from 'vue';
import Vuetify from '../../helpers/vuetify';

page('/orders/reports', function () {
  const flash = new Flash('.flash--main');

  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],
    components: {
      ValidationProvider,
      ValidationObserver,
    },

    data() {
      return {
        orderConfig: {},

        isPageLoading: null,
        isReportGenerating: null,

        csrfToken: null,

        orderTypes: [],
        managers: [],
        clientNamePattern: null,
        orderCreationDates: [],
        orderCreatedAtFrom: null,
        orderCreatedAtTo: null,
      };
    },

    async mounted() {
      this.isPageLoading = true;

      try {
        await Promise.all([this.getCsrfToken(), this.getOrderConfig()]);

        this.isPageLoading = false;
      } catch (err) {
        console.error(err);

        flash.flash('Could not load the page. Please reload the page and try again.', 'error', false);
      }
    },

    methods: {
      async getCsrfToken() {
        this.csrfToken = await SecurityService.getCsrfToken();
      },

      async getOrderConfig() {
        this.orderConfig = await OrderConfigService.getOrderConfig();
      },

      async submitDownloadReport() {
        flash.clear();

        const isFormValid = await this.$refs.formValidationObserver.validate();
        if (!isFormValid) {
          scrollToFirstFormValidationError(this.$el);
          return;
        }

        this.isReportGenerating = true;

        try {
          const { data, filename } = await OrderService.generateOrdersHistoryReportXlsx(
            {
              order_types: this.orderTypes,
              managers: this.managers,
              client_name_pattern: this.clientNamePattern,
              created_at_from: this.orderCreatedAtFrom,
              created_at_to: this.orderCreatedAtTo,
            },
            this.csrfToken,
          );

          saveAs(data, filename);

          flash.flash('Report downloaded.', 'success', true);
        } catch (err) {
          console.error(err);

          let data;
          try {
            data = JSON.parse(await err.response.data.text());
          } catch (err) {
            data = null;
          }

          flash.flashFromBackendErrorResponse(
            data,
            'Could not generate the report. Please reload the page, narrow the search filters and try again.',
          );
        } finally {
          this.isReportGenerating = false;
        }
      },
    },
  });

  app.$mount('#app');
});
