export const CURRENCY = 'EUR';
export const JSON_STRINGIFY_SPACE = 4;

export const ITEMS_PER_PAGE_OPTIONS = [25, 50, 100, 250, 500, 1000];
export const ITEMS_PER_PAGE_DEFAULT = 50;

export const FLASH_CLOSE = 12 * 1000; // 12 seconds

export const SIDEBAR_COLLAPSE_WIDTH = 992;
export const SIDEBAR_HIDE_WIDTH = 720;

export const ORDERS_MAIN_PAGE_URL = '/orders';
export const ORDERS_LIST_PICKUP_LOCATIONS_URL = '/orders/config/pickup';
export const ORDERS_TYPES_LIST = [1, 2, 3, 4];
export const ORDER_ACTIVE_STATUS_GROUP = 1;
export const ORDER_FINISHED_STATUS_GROUP = 2;
export const ORDER_STATUS_GROUPS = [ORDER_ACTIVE_STATUS_GROUP, ORDER_FINISHED_STATUS_GROUP];
export const ORDER_INVOICE_NUMBER_SET = 1;
export const ORDER_INVOICE_NUMBER_NOT_SET = 2;
export const ORDER_TABLE_CONFIG_LOCAL_STORAGE_KEY = 'orderTableConfig?v=6';
export const ORDER_NEW_LAST_SELECTED_ORDER_TYPE_LOCAL_STORAGE_KEY = 'newOrderLastSelectedOrderType?v=1';
export const ORDER_MATERIAL_FIELDS = ['print_material', 'print_laminate'];
export const ORDER_STATUS_ID_DONE = 4;
export const ORDER_STATUS_ID_ERROR = 7;

export const CLIENTS_MAIN_PAGE_URL = '/clients';
export const CLIENT_TYPES = [
  { text: 'Company', value: 'company' },
  { text: 'Person', value: 'person' },
];
export const DEFAULT_SELECTED_CLIENT_TYPES = ['company', 'person'];
export const CLIENT_SOURCES = [
  { text: 'Internal', value: 'internal' },
  { text: 'Scoro', value: 'crm' },
];
export const DEFAULT_SELECTED_CLIENT_SOURCES = ['internal', 'crm'];
