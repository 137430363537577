export const getOrderIdFromOrderEditPage = function (url) {
  const urlObj = new URL(url);

  const orderId = urlObj.pathname.split('/')[3];
  if (!orderId) {
    throw new Error('Order ID not found');
  }

  return orderId;
};

export const getOrderEditExternalDetails = function (url) {
  const urlObj = new URL(url);

  const orderId = urlObj.pathname.split('/')[4];
  if (!orderId) {
    throw new Error('Order ID not found');
  }

  const secretKey = urlObj.searchParams.get('secret_key') || urlObj.pathname.split('/')[5];
  if (!secretKey) {
    throw new Error('Secret Key not found');
  }

  return { orderId, secretKey };
};

export const getClientIdFromClientEditPage = function (url) {
  const urlObj = new URL(url);

  const clientId = urlObj.pathname.split('/')[3];
  if (!clientId) {
    throw new Error('Client ID not found');
  }

  return clientId;
};

export const getPickupLocationIdFromEditPickupLocationPage = function (url) {
  const urlObj = new URL(url);

  const pickupLocationId = urlObj.pathname.split('/')[5];
  if (!pickupLocationId) {
    throw new Error('Pickup Location ID not found');
  }

  return pickupLocationId;
};
