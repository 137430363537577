<template>
  <v-container>
    <v-row>
      <v-col>
        <div id="qr-code-scanner" ref="qrCodeScanner"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';

export default {
  props: {
    qrbox: {
      type: Number,
      default: 150,
    },
    fps: {
      type: Number,
      default: 25,
    },
  },

  data() {
    return {
      scanner: null,
    };
  },

  methods: {
    async start() {
      const scanner = new Html5Qrcode(this.$refs.qrCodeScanner.id);

      await scanner.start(
        { facingMode: 'environment' }, // Use back camera if available
        {
          fps: this.fps,
          qrbox: { width: this.qrbox, height: this.qrbox },
        },
        this.onSuccessfulScan,
      );

      this.scanner = scanner;
    },

    async stop() {
      if (this.scanner) {
        await this.scanner.stop();
        this.scanner.clear();
      }
    },

    onSuccessfulScan(decodedText) {
      this.$emit('onSuccessfulScan', decodedText);
    },
  },
};
</script>
