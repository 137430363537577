<template>
  <v-container>
    <v-row>
      <div class="flash flash--client-form"></div>
    </v-row>

    <validation-observer ref="clientFormValidationObserver" :disabled="validationDisabled">
      <v-form @submit.prevent="submitClientForm">
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" name="Client Name" rules="required">
              <v-text-field
                v-model="client.name"
                v-required
                label="Client Name"
                :error-messages="errors"
                :disabled="isPageLoading || isCrmClient"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" name="Client Type" rules="required">
              <v-select
                v-model="client.clientType"
                v-required
                label="Client Type"
                :items="clientTypes"
                :error-messages="errors"
                :disabled="isPageLoading || isCrmClient"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row v-if="isCrmClient">
          <v-col cols="12" md="6">
            <v-text-field v-model="client.crmId" v-required label="Scoro Client ID" disabled>
              <template #append-outer>
                <a :href="getScoroCrmClientLink(client)" target="_blank">
                  <v-btn small icon>
                    <v-icon>open_in_new</v-icon>
                  </v-btn>
                </a>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="client.invoiceEmail" label="Invoice Email" disabled></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-h6 font-weight-regular mb-2">Contacts</div>

            <v-radio-group v-model="primaryContactIndex">
              <draggable
                v-model="client.contacts"
                ghost-class="draggable__ghost"
                handle=".draggable__dragger"
                :animation="200"
                :disabled="isPageLoading"
                @end="initPrimaryContact"
              >
                <transition-group class="list-group" type="transition">
                  <div v-for="(contact, index) in client.contacts" :key="`contact-${index}`">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Client Email"
                      rules="required|email|uniqueContactEmails|primaryContactRequired"
                    >
                      <v-text-field
                        v-model="contact.email"
                        label="Client Email"
                        :error-messages="errors"
                        :disabled="isPageLoading"
                      >
                        <template #prepend>
                          <v-icon class="draggable__dragger" small>menu</v-icon>
                        </template>

                        <template #append-outer>
                          <div class="d-flex align-center">
                            <v-tooltip top>
                              <template #activator="{ on, attrs }">
                                <v-radio
                                  v-bind="attrs"
                                  class="mb-0"
                                  :value="index"
                                  :disabled="isPageLoading"
                                  v-on="on"
                                  @change="setPrimaryContact(contact)"
                                ></v-radio>
                              </template>
                              <span>Primary Contact</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  :disabled="isPageLoading"
                                  icon
                                  v-on="on"
                                  @click="deleteContact(contact)"
                                >
                                  <v-icon>delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                </transition-group>
              </draggable>
            </v-radio-group>

            <v-btn color="primary" small outlined :disabled="isPageLoading" @click="addContact">Add Contact</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto">
            <v-switch v-model="client.sendClientEmails" label="Send Client Emails" :disabled="isPageLoading"></v-switch>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          class="mt-4"
          outlined
          text
          type="submit"
          :disabled="isPageLoading"
          :loading="isPageLoading"
        >
          {{ isNewClientCreation ? 'Create Client' : 'Edit Client' }}
        </v-btn>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import draggable from 'vuedraggable';

import { CLIENT_TYPES } from '../config';
import { ClientService } from '../services/ClientService';
import { SecurityService } from '../services/SecurityService';
import { getScoroCrmClientLink } from '../helpers/scoroCrm';
import Flash from '../helpers/Flash';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    draggable,
  },

  props: {
    clientId: {
      type: Number,
      required: false,
      default: null,
    },

    clientName: {
      type: String,
      required: false,
      default: null,
    },

    validationDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isPageLoading: false,

      primaryContactIndex: null,

      csrfToken: null,

      client: {
        id: this.clientId,
        crmId: null,
        name: this.clientName,
        clientType: null,
        contacts: [],
        sendClientEmails: true,
      },

      clientTypes: CLIENT_TYPES,
    };
  },

  computed: {
    isNewClientCreation() {
      return !this.clientId;
    },

    isCrmClient() {
      return !!this.client.crmId;
    },
  },

  watch: {
    async clientId(newClientId) {
      this.clearFormInputs();

      this.client.id = newClientId;

      await this.initFormForClientCreationOrEdit();
    },

    clientName(newClientName) {
      this.clearFormInputs();

      this.client.name = newClientName;
    },
  },

  created() {
    extend('uniqueContactEmails', {
      validate: this.validateUniqueClientEmails,
      message: 'Client emails must be unique.',
    });

    extend('primaryContactRequired', {
      validate: this.validatePrimaryContactRequired,
      message: 'Mark one contact as primary.',
    });
  },

  async mounted() {
    await this.initFormForClientCreationOrEdit();
  },

  methods: {
    getScoroCrmClientLink,

    initPrimaryContact() {
      this.primaryContactIndex = this.client.contacts.findIndex((contact) => contact.isPrimary === true);
    },

    async setPrimaryContact(selectedContact) {
      this.client.contacts.forEach((contact) => {
        contact.isPrimary = contact === selectedContact;
      });

      this.initPrimaryContact();

      await this.validateClientForm();
    },

    async addContact() {
      // Prevent adding a new contact if at least one contact has no email
      if (this.client.contacts.some((contact) => !contact.email)) {
        await this.validateClientForm();
        return;
      }

      // Init first contact as primary
      const isPrimary = this.client.contacts.length === 0;

      this.client.contacts.push({
        email: null,
        isPrimary: isPrimary,
      });

      this.initPrimaryContact();

      await this.validateClientForm();
    },

    async deleteContact(selectedContact) {
      this.client.contacts = this.client.contacts.filter((contact) => contact !== selectedContact);

      this.initPrimaryContact();

      await this.validateClientForm();
    },

    async getCsrfToken() {
      this.csrfToken = await SecurityService.getCsrfToken();
    },

    async initFormForClientCreationOrEdit() {
      const flash = new Flash('.flash--client-form');
      flash.clear();

      this.isPageLoading = true;
      try {
        await this.getCsrfToken();

        if (!this.isNewClientCreation) {
          this.client = await ClientService.getClientById({ clientId: this.clientId });
          this.initPrimaryContact();
        }

        this.isPageLoading = false;
      } catch (err) {
        console.error(err);

        flash.flash('Could not load the page. Please reload the page and try again.', 'error', false);
      }
    },

    async submitClientForm() {
      const flash = new Flash('.flash--client-form');
      flash.clear();

      const isFormValid = await this.validateClientForm();
      if (!isFormValid) {
        return;
      }

      this.isPageLoading = true;

      const request = {
        data: {
          id: this.client.id || null,
          name: this.client.name,
          client_type: this.client.clientType,
          contacts: this.client.contacts.map((contact) => {
            return {
              email: contact.email,
              is_primary: contact.isPrimary,
            };
          }),
          send_client_emails: this.client.sendClientEmails,
        },
        csrfToken: this.csrfToken,
      };

      try {
        let client;

        if (this.isNewClientCreation) {
          client = await ClientService.createClient(request);
        } else {
          client = await ClientService.updateClient(request);
        }

        this.$emit('update:client', client);
      } catch (err) {
        console.error(err);

        flash.flashFromBackendErrorResponse(
          err.response?.data,
          'Could not save the client. Please reload the page and try again.',
        );
      } finally {
        this.isPageLoading = false;
      }
    },

    async validateClientForm() {
      this.$refs.clientFormValidationObserver.reset();
      return await this.$refs.clientFormValidationObserver.validate();
    },

    clearFormInputs() {
      this.client = {
        id: null,
        crmId: null,
        name: null,
        clientType: null,
        contacts: [],
        sendClientEmails: true,
      };
    },

    validateUniqueClientEmails() {
      const emails = this.client.contacts.map((contact) => contact.email);
      const uniqueEmails = [...new Set(emails)];
      return emails.length === uniqueEmails.length;
    },

    validatePrimaryContactRequired() {
      return this.client.contacts.some((contact) => contact.isPrimary);
    },
  },
};
</script>
