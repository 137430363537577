import axios from 'axios';

export class UserService {
  /**
   *
   * @returns Array of all users
   */
  static async listUsers() {
    const response = await axios.get('/api/v2/users');
    return response.data.users;
  }

  /**
   *
   * @returns Currently logged in user object
   */
  static async getCurrentUser() {
    const response = await axios.get('/api/v2/users/current');
    return response.data;
  }
}
