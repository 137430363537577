<template>
  <div>
    <validation-provider v-slot="{ errors }" name="Pickup Location" rules="pickupLocationRequired" class="p-0">
      <v-select
        v-required
        :readonly="requireQrCodeScan"
        label="Pickup Location"
        :value="value"
        :items="pickupLocations"
        item-value="id"
        item-text="name"
        :error-messages="errors"
        @input="setValue"
      >
        >
        <template #append-outer>
          <v-btn small outlined color="primary" @click="openQrCodeScannerDialog">
            <v-icon>qr_code_scanner</v-icon>
          </v-btn>
        </template>
      </v-select>
    </validation-provider>

    <v-dialog v-model="isQrCodeScannerDialogOpen" fullscreen>
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h6">Scan Pickup Location QR Code</div>

          <v-btn text icon @click="closeQrCodeScannerDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <qr-code-scanner
            ref="qrCodeScanner"
            :qrbox="170"
            class="qr-code-scanner"
            @onSuccessfulScan="onSuccessfulScan"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import QrCodeScanner from './QrCodeScanner.vue';

export default {
  components: {
    QrCodeScanner,
    ValidationProvider,
  },

  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    pickupLocations: {
      type: Array,
      required: true,
    },
    requireQrCodeScan: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pickupLocation: null,
      isQrCodeScannerDialogOpen: false,
    };
  },

  watch: {
    isQrCodeScannerDialogOpen(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.qrCodeScanner.start();
        });
      } else {
        this.$nextTick(() => {
          this.$refs.qrCodeScanner.stop();
        });
      }
    },
  },

  created() {
    extend('pickupLocationRequired', {
      ...required,
      message: 'The {_field_} field is required for Done orders',
    });
  },

  methods: {
    openQrCodeScannerDialog() {
      this.isQrCodeScannerDialogOpen = true;
    },

    closeQrCodeScannerDialog() {
      this.isQrCodeScannerDialogOpen = false;
    },

    onSuccessfulScan(qrCodePayload) {
      const pickupLocation = this.pickupLocations.find(
        (pickupLocation) => pickupLocation.qrCodePayload === qrCodePayload,
      );

      if (pickupLocation) {
        this.setValue(pickupLocation.id);
        this.closeQrCodeScannerDialog();
      }
    },

    setValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.qr-code-scanner {
  max-width: 600px;
  margin: 0 auto;
}
</style>
