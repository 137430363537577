<template>
  <order-config-pickup-location-form
    :pickup-location-id="getPickupLocationIdToEdit()"
    @update:pickup-location="redirectToListPickupLocationsPage"
  ></order-config-pickup-location-form>
</template>

<script>
import { ORDERS_LIST_PICKUP_LOCATIONS_URL } from '../config';
import { getPickupLocationIdFromEditPickupLocationPage } from '../helpers/routeParams';
import OrderConfigPickupLocationForm from './OrderConfigPickupLocationForm.vue';

export default {
  components: {
    OrderConfigPickupLocationForm,
  },

  methods: {
    getPickupLocationIdToEdit() {
      return Number(getPickupLocationIdFromEditPickupLocationPage(window.location.href));
    },

    redirectToListPickupLocationsPage() {
      window.location.href = ORDERS_LIST_PICKUP_LOCATIONS_URL;
    },
  },
};
</script>
