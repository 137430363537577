import _ from 'lodash';

export const dataTableLoadData = async function (
  saveInVariable,
  queryFunc,
  responseVariable = null,
  serverSidePagination = false,
) {
  try {
    this.loading = true;

    const response = await queryFunc();

    this[saveInVariable] = responseVariable ? response[responseVariable] : response;

    if (serverSidePagination) {
      this.pageCount = response.pagination.total_pages;
      this.serverItemsLength = response.pagination.total_records;
    }

    this.error = false;
  } catch (err) {
    console.error(err);
    // Expected error messages
    if (err.response && err.response.status === 404) return;
    // If session has expired, restart page. It will prompt to login again
    if (err.response && err.response.status === 401) location.reload();
    this.error = true;
  } finally {
    this.loading = false;
  }
};

export const arrayToCommaString = function (val) {
  if (!val) return '';
  return val.join(', ');
};

/**
 * Converts given HTML string to DOM Element
 *
 * @param {String} htmlString
 * @returns {Element}
 */
export const stringToDOMElement = function (htmlString) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = htmlString;
  return wrapper.firstChild;
};

/**
 * Deep diff between two object, using lodash
 *
 * https://gist.github.com/Yimiprod/7ee176597fef230d1451
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}
