<template>
  <client-form :client-id="getClientIdToEdit()" @update:client="redirectToClientsMainPage"></client-form>
</template>

<script>
import { CLIENTS_MAIN_PAGE_URL } from '../config';
import { getClientIdFromClientEditPage } from '../helpers/routeParams';
import ClientForm from './ClientForm.vue';

export default {
  components: {
    ClientForm,
  },

  methods: {
    getClientIdToEdit() {
      return Number(getClientIdFromClientEditPage(window.location.href));
    },

    redirectToClientsMainPage() {
      window.location.href = CLIENTS_MAIN_PAGE_URL;
    },
  },
};
</script>
