import { SIDEBAR_COLLAPSE_WIDTH, SIDEBAR_HIDE_WIDTH } from '../config';

const sidebarEl = document.querySelector('.sidebar');
const navbarEl = document.querySelector('.navbar');
const contentEl = document.querySelector('.content');
const overlayEl = document.querySelector('.sidebar-overlay');

const collapseSidebarBtn = document.querySelector('.sidebar__collapse-btn');
const hideSidebarBtn = document.querySelector('.navbar__sidebar-hide-btn');

// Resets sidebar settings to initial state
const resetSidebar = function () {
  localStorage.setItem('sidebarState', 'expanded');
  sidebarEl.classList.remove('sidebar--collapsed', 'sidebar--hidden');
  navbarEl.classList.remove('navbar--sidebar-collapsed', 'navbar--sidebar-hidden', 'sidebar--ontop');
  contentEl.classList.remove('content--sidebar-collapsed', 'content--sidebar-hidden', 'sidebar--ontop');
  overlayEl.classList.remove('sidebar-overlay--active');
};

const collapseSidebar = function () {
  resetSidebar();
  localStorage.setItem('sidebarState', 'collapsed');
  sidebarEl.classList.add('sidebar--collapsed');
  navbarEl.classList.add('navbar--sidebar-collapsed');
  contentEl.classList.add('content--sidebar-collapsed');
};

const hideSidebar = function () {
  resetSidebar();
  localStorage.setItem('sidebarState', 'hidden');
  sidebarEl.classList.add('sidebar--hidden');
  navbarEl.classList.add('navbar--sidebar-hidden');
  contentEl.classList.add('content--sidebar-hidden');
  overlayEl.classList.remove('sidebar-overlay--active');
};

const showSidebar = function () {
  resetSidebar();
  if (window.innerWidth < SIDEBAR_HIDE_WIDTH) {
    overlayEl.classList.add('sidebar-overlay--active');
    navbarEl.classList.add('sidebar--ontop');
    contentEl.classList.add('sidebar--ontop');
  }
};

const isSidebarCollapsed = function () {
  return localStorage.getItem('sidebarState') === 'collapsed' ? true : false;
};

const isSidebarHidden = function () {
  return localStorage.getItem('sidebarState') === 'hidden' ? true : false;
};

const toggleCollapseSidebar = function () {
  const sidebarCollapsed = isSidebarCollapsed();
  if (sidebarCollapsed === true) showSidebar();
  if (sidebarCollapsed === false) collapseSidebar();
};

const toggleHideSidebar = function () {
  const sidebarHidden = isSidebarHidden();
  if (sidebarHidden === true) showSidebar();
  if (sidebarHidden === false) hideSidebar();
};

const resizeSidebarOnWidth = function () {
  if (window.innerWidth > SIDEBAR_HIDE_WIDTH && window.innerWidth < SIDEBAR_COLLAPSE_WIDTH) {
    collapseSidebar();
    return;
  }
  if (window.innerWidth < SIDEBAR_HIDE_WIDTH) {
    hideSidebar();
    return;
  }
  showSidebar();
};

// Event listeners
const initSidebar = function () {
  const sidebarHidden = isSidebarHidden();
  if (sidebarHidden === true) {
    hideSidebar();
    return;
  }
  const sidebarCollapsed = isSidebarCollapsed();
  if (sidebarCollapsed === true) {
    collapseSidebar();
    return;
  }
  resizeSidebarOnWidth();
};

// Run if sidebar exists on the current page
if (collapseSidebarBtn) {
  collapseSidebarBtn.addEventListener('click', toggleCollapseSidebar);
  hideSidebarBtn.addEventListener('click', toggleHideSidebar);
  overlayEl.addEventListener('click', hideSidebar);
  window.addEventListener('resize', resizeSidebarOnWidth);
  initSidebar();
}
