import OrderConfigListPickupLocationsPage from '../../components/OrderConfigListPickupLocationsPage.vue';
import Vue from 'vue';
import _ from 'lodash';

import { page } from '../../helpers/page';
import Vuetify from '../../helpers/vuetify';

page('/orders/config/pickup', function () {
  const app = new Vue({
    vuetify: Vuetify,
    components: {
      OrderConfigListPickupLocationsPage,
    },
  });

  app.$mount('#app');
});
